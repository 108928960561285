import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyAPYChkmbSbQyNbORAuv4rkzQFMphIrmoM",
    authDomain: "blues-swapp.firebaseapp.com",
    projectId: "blues-swapp",
    storageBucket: "blues-swapp.appspot.com",
    messagingSenderId: "78251172671",
    appId: "1:78251172671:web:8a7ff6dcfb109da0df298a",
    measurementId: "G-RDX4LWW3SC"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;

export { db, storage, auth, currentUser };
