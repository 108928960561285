<template>
    <v-row class="justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" class="text-left pa-5 ">
            <v-row class="justifty-left" v-if="preEventFormCompleted">
                <v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-left px-7">
                        <div class="heading5 secondary--text">Pre-event Screening</div>
                        <div class="body1">Thank you for completing the pre-event certification for the following event.</div>
                        <div class="heading6 mt-2">{{ formatDatetime(eventActive.eventDate, 'x', 'DD MMM YYYY') }} - {{ eventActive.eventName }}</div>
                        <div class="heading6">{{ lookupVenue(eventActive.venueId, 'venueName') }}</div>
                        <v-btn class="mt-5 secondary" to="/events/checkinevent">Continue</v-btn>
                    </v-col>
                </v-col>
            </v-row>
            <v-row class="justifty-left" v-if="!preEventFormCompleted">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-left px-7">
                    <div class="heading5 secondary--text">Pre-event Screening</div>
                    <div class="body1">You must answer the following questions at least 48 hours before the following event.</div>
                    <div class="heading6 mt-2">{{ formatDatetime(eventActive.eventDate, 'x', 'DD MMM YYYY') }} - {{ eventActive.eventName }}</div>
                    <div class="heading6">{{ lookupVenue(eventActive.venueId, 'venueName') }}</div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-left">
                       <v-form ref="radioQuestions" v-model="valid" lazy-validation>
                    <v-row
                      v-masonry
                      origin-left="true"
                      horizontal-order="true"
                      transition-duration="0.3s"
                      item-selector=".item"
                      class=""
                    >
                        <v-col
                          v-masonry-tile
                          class="item"
                          v-for="(item, index) in filteredQuestions" :key="item.id"
                          xs="12"
                          sm="12"
                          md="4"
                          lg="6"
                          xl="6"  
                        >
                            <div class="card pa-5">
                                <div class="heading7 font-weight-bold">{{item.questionName}}</div>
                                <div v-if="item.questionDescription !== null && item.questionDescription !== ''" style="white-space: pre-wrap;" class="mt-2" v-html="item.questionDescription"></div>
                                <div class="">
                                    <v-radio-group :rules="[(v) => !!v || 'A response is required.']"
          required row v-model="filteredQuestions[index].questionResult">
                                      <v-radio dark v-for="option in orderedOptions(item.questionOptions)" :key="option" :label="option" :value="option"></v-radio>
                                      <v-radio dark v-for="option in orderedOptions()" :key="option" :label="option" :value="option"></v-radio>
                                    </v-radio-group>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                       </v-form>
                    <v-row>
                        <v-col cols="12" class="d-flex align-center justify-end pa-5">
                            <v-spacer />
                            <div class="mr-2">
                                <v-btn depressed @click="goBack()" class="grey">Close</v-btn>
                            </div>
                            <div>
                                <v-btn depressed @click="createPass()" color="secondary">Submit</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
            >
                <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
            </transition>
        </v-col>
    </v-row>
</template>

<script>
class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

export default {
    data(){
        return {
            preEventFormCompleted: false,
            preEventForm: {
                alert: 0,
                answers: [],
                eventDate: null,
                eventId: null,
                venueId: null,
                userId: null,
                status: null
            },
            snackbarKey: 0,
            snackbar: {},
            questions: [],
            questionResults: [], 
            eventActive: {},
            filteredQuestions: [],
            venues: [],
            valid: true //for validation
        }
    },
    computed: {
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        orderedOptions: function(options) {
            return this.$sortBy(options).reverse();
        },
        async createPass() {
              if (this.$refs.radioQuestions.validate()) {
            this.preEventForm.alert = 0;
            var questions = JSON.parse(JSON.stringify(this.filteredQuestions));
            for (var i = 0; i < questions.length; i++) {
                var q = questions[i];
                delete q.questionName;
                delete q.questionPosition;
                delete q.venueIds;
                delete q.questionOptions;
                delete q.questionDescription;
                delete q.updatedDatetime;
                delete q.createdDatetime;
                delete q.createdUserId;
                delete q.updatedUserId;
                if (q.questionCorrect !== 'N/A') {
                    if (q.questionCorrect !== q.questionResult) {
                        this.preEventForm.alert++
                    }
                } else {
                    // DO NOT ALERT ON N/A QUESTIONS
                }
            }
            if (this.preEventForm.alert > 0) {
                this.preEventForm.status = "DECLINED";
            } else {
                this.preEventForm.status = "APPROVED";
            }
            this.preEventForm.answers = questions;
            var result = await this.createDocument('passes', this.preEventForm) // add menu through mixins
            if (result.code === 1) {
                this.snackbar = new SNACKBAR(true,'success','Submited',2000); // update snack bar with success
                this.snackbarKey++
                this.preEventFormCompleted = true;
//                this.eventForm = new EVENTFORM(null, '', '', null, null, null, null, null); // clear event form
                //this.$router.push ("/events/preeventcert");
            } else {
                this.snackbar = new SNACKBAR(true,'error','Error Submitting',3000); // update snack bar with error
                this.snackbarKey++
                this.preEventFormCompleted = false;
            }
        }else{
            this.snackbar = new SNACKBAR(true,'error','Fill in every field in order to proceed.',3000); 
            this.snackbarKey++;
        }
        },
    },
    async created() { 
        this.readDocuments('venues', 'venues'); // get list of passs from database
        this.readDocuments('questions', 'questions'); // get list of passs from database
        var passResult = await this.readDocumentByKeyValue('passes', this.$store.getters.currUser.userID, 'eventId', this.$route.params.id);
        if (passResult[0] !== undefined) {
            this.preEventFormCompleted = true;
        } else {
            this.preEventFormCompleted = false;
        }
        var eventResult = await this.readDocumentById('events', this.$route.params.id);
        var filteredQuestions = JSON.parse(JSON.stringify(this.questions));
        filteredQuestions = await this.$sortBy(filteredQuestions, 'questionPosition');
        // ! questions.venueIds must be array by default
        filteredQuestions = filteredQuestions.filter(venue => venue.venueIds.includes(eventResult.venueId) || venue.venueIds.length === 0);
        this.filteredQuestions = filteredQuestions
        // console.log('')
        // this.filteredQuestions = JSON.parse(JSON.stringify(this.questions));
        // console.log('this.filteredQuestions = ' + this.filteredQuestions)
        this.preEventForm.userId = this.$store.getters.currUser.userID; // add userId to document
        this.preEventForm.eventId = eventResult.id;
        this.preEventForm.eventDate = eventResult.eventDate;
        this.preEventForm.venueId = eventResult.venueId;
        this.eventActive = eventResult;
    },
    mounted() {
            this.$redrawVueMasonry()
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    }
}
</script>

