<template>
  <div class="home">
    <v-progress-linear v-if="loading === true" indeterminate color="primary"></v-progress-linear>
    
    <v-row class="px-5 pt-4 justify-center">
     <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <div class="heading6 white--text">Registration<br /></div>
        <p class="mt-1 text-left body2">
          Please use the form below to register as a user.  
        </p>
      </v-col>
    </v-row>
    
    <!-- start of USER-INPUT -->
    <v-row  class="px-5 mt-0 justify-center">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">  
         <v-window v-model="step">
          <!--GENERAL-DETAILS-WINDOW-->
          <v-window-item :value="1">
            <v-form ref="registerUser" v-model="valid" lazy-validation>
              <v-text-field
                solo
                class="mb-1"
                v-model.trim="$store.getters.getRegisterUserEmail"
                label="Email"
                hide-details="auto"
                :rules="emailRules"
                :readonly="$store.getters.getRegisterUserEmail != null"
              ></v-text-field>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.fullName"
                label="Full Name"
                hide-details="auto"
                :rules="nameRules"
                required
              ></v-text-field>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.contactNumber"
                label="Contact Number"
                hide-details="auto"
                :rules="telephoneNumberRules"
                required
              ></v-text-field>
              
              <!-- <v-text-field
                solo
                class="mb-1"
                v-model="user.company"
                label="Company/Organisation"
                hide-details="auto"
                :rules="nameRules"
                required
              ></v-text-field>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.jobTitle"
                label="Job title"
                hide-details="auto"
                :rules="nameRules"
                required
              ></v-text-field> -->
              <v-select
                solo
                class="mb-1"
                v-model="user.visitorType"
                label="Visitor Type"
                v-if="JSON.stringify(lookupValues !== '[]')"
                :items="visitorTypes"
                hide-details="auto"
                :rules="[(v) => !!v || 'Required']"
                required
              ></v-select>
              <div class="d-flex align-start mt-5" >
                <div style="width: 100px !important;" class="">
                  <v-checkbox
                    color="white"
                    v-model="user.privacyPolicyConsent"
                    hide-details
                    :rules="[(v) => !!v]"
                    required
                    dark
                    class="d-flex align-start"
                    style="position: relative !important; bottom:20 !important;">
                  </v-checkbox>
                </div>
                <div class="text-left">
                    I have completed this form as accurately as possible and accept the <span style="cursor:pointer !important;" v-on:click="privacyDialog = true" class="font-weight-bold secondary--text"> Terms and Conditions </span>
                </div>
                </div>
            </v-form>
            <!--NEXT-BUTTON-->
            <div class="text-right">
              <v-btn @click="next" :loading="loading1" :disabled="loading1" large class="buttonmin mt-3 secondary">Next</v-btn>
            </div>
          </v-window-item>

           <!--PASSWORD-WINDOW-->
          <v-window-item :value="2">
            <v-form ref="userPassword" v-model="valid1" lazy-validation>
              <v-text-field
                v-model.trim="password"
                solo
                class="mb-1"
                hide-details="auto"
                label="Password"
                :append-icon="show ? 'icons8-eye' : 'icons8-invisible'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :rules="passwordRules"
                required
              ></v-text-field>

              <v-text-field
                v-model.trim="confirmPassword"
                solo
                class="mb-2"
                hide-details="auto"
                label="Confirm Password"
                :append-icon="show1 ? 'icons8-eye' : 'icons8-invisible'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                :rules="[confirmRules, passwordConfirmationRule]"
                required
              ></v-text-field>
            </v-form>
            <!--Register button -->
            <v-row class="px-4 mb-4">
              <v-btn @click="step--" class=" mt-3 secondary">Back</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="register" :loading="loading" :disabled="loading" class=" mt-3 secondary">Register</v-btn>
            </v-row>
          </v-window-item>

          <!-- Error message -->
          <v-window-item :value="3">
            <div class="pa-4 text-center" v-if="accountCreated === true">
              <v-progress-linear indeterminate color="primary" ></v-progress-linear>
              <h3 class="title font-weight-light mb-2">Welcome to Cardiff Blues Events</h3>
              <span class="caption grey--text">Thanks for signing up! You will be redirected to the home page</span>
            </div>
            <div class="pa-4 text-center" v-else>
              <h3 class="title font-weight-light mb-2">Something went wrong</h3>
              <h4 v-if="registerErrorMessage != null">{{registerErrorMessage}}</h4>
              <span class="caption grey--text">Try again!</span>
            </div>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>

    <v-row class="px-5 justify-center">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-center"> 
        <appfooter />
      </v-col>
    </v-row>
    
    <!-- end of USER-INPUT -->

      <!--notification-component-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        mode="out-in">
        <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
      </transition>
     <v-dialog style="z-index: 3000 !important;" v-model="privacyDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card class="background">
        <v-toolbar dark color="secondary heading5 black--text">
          Privacy and Terms & Conditions
          <v-spacer></v-spacer>
          <v-icon class="black--text" @click="privacyDialog = false">mdi-close</v-icon>
        </v-toolbar>
        <PrivacyTermsConditions />
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import PrivacyTermsConditions from "../../components/termsAndConditions.vue";
import axios from "axios";

class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

export default {
  name: "Register",
  components: {
    PrivacyTermsConditions,
  },
  data() {
    return {
      snackbarKey: 0, // notifications
      snackbar: {}, //notifications
      step: 1,
      valid: true,
      valid1: true,
      show: false,
      show1: false,
      user: {
        userID: "",
        userLevel: 10,
        userType: "standard",
        fullName: "",
        contactNumber: "",
        email: "",
        company: "",
        jobTitle: "",
        visitorType: "Spectator",
        privacyPolicyConsent: false,
        accountCreatedAt: "",
      },
      visitorTypes: [
        // { text: "Broadcast", value:"broadcast" },
        // { text: "Written Media", value:"writtenmedia" },
        // { text: "Photographers", value:"photographers" },
        // { text: "Medical", value:"medical" },
        // { text: "Cardiff Blues Group Staff", value:"cardiffbluesgroupstaff" },
        // { text: "Technical", value:"technical" },
        // { text: "Team Support", value:"teamsupport" },
        // { text: "Safety Steward", value:"safetysteward" },                                    
        // { text: "Other", value:"other" }, 
        { text: "Spectator", value:"Spectator"},                                     
      ],
      password: "", // password input
      confirmPassword: "", // check password
      accountCreated: true, // show thank you/ try gain message at the end
      loading: false,
      loader1: null,
      loading1: false,
      privacyDialog: false,
      lookupValues: [],

      // * VALIDATIONS RULES
      nameRules: [(v) => !!v || "Full name is required"],
      confirmRules: [(v) => !!v || "Confirm password required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      telephoneNumberRules: [(v) => !!v || "Telephone Number is required"],
      integration: [],
      registerErrorMessage: null,
    };
  },
  watch: {
    loader1() {
      const l = this.loader1;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader1 = null;
    },
  },
  computed: {
    // * Checks if the password matches the confirm password
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || "Password must match";
    },
  },
  methods: {
    next() {
      if (this.$refs.registerUser.validate()) {
        this.step++; // * move to the next step 
      } else {
        // // console.log("not valid");
        // ! did not pass validation
        this.snackbar = new SNACKBAR(true,'error',"Fill out all the fields correctly in order to proceed.",500); // update snack bar with error
        this.snackbarKey++;
      }
    },
    register() {
      var t = this;
      if (this.$refs.userPassword.validate()) {
        this.step++; // * move to the next step 
        t.user.email = t.$store.getters.getRegisterUserEmail;
        t.user.email = t.user.email.toLowerCase();
        setTimeout(function() {
        // * create user
        t.$firebase.auth.createUserWithEmailAndPassword(t.user.email, t.password).then((user) => {
            // //console.log(JSON.stringify(user, null, 2));
            t.user.userID = user.user.uid; // * assign firebase auth user id to firebase user in collection
            t.user.accountCreatedAt = moment().toISOString();
            ////console.log("uid: " + user.uid + "\nsecond type: " + user.user.uid);
            /////console.log("User: " + JSON.stringify(t.user, null, 2));
           
             // * add to the users collection
            t.$firebase.db.collection("users").doc(user.user.uid).set(t.user).then((Result) => {
                console.log("result: " + Result);
              })
              .catch((err) => {
                console.log(err);
              });
            t.accountCreated = true;
             // * assign this user to the current logged in user
            //t.$store.commit("setUser", user);
            t.snackbar = new SNACKBAR(true,'success',"Your account was created.",500); // update snack bar with success
            t.snackbarKey++;
            
            // * send verification email to user
            var currUser = t.$firebase.auth.currentUser;
            currUser.sendEmailVerification().then(function() {
                t.snackbar = new SNACKBAR(true,'yellow',"An account verification email has been sent to you.",500); // update snack bar with error
                t.snackbarKey++;
              })
              .catch(function(error) {
                // ! verification email error
                console.log("send verification email error: " + error);
                t.snackbar = new SNACKBAR(true,'error',error,500); // update snack bar with error
                t.snackbarKey++;
              });
             
             // ? send custom email
              if(t.integration != undefined && t.integration[0].enabled === true){
                axios.get(t.integration[0].functionURL +"?fullName=" + t.user.fullName + "&email=" + t.user.email).then(response => {

                  if (response.data.result === true) {
                      t.snackbar = new SNACKBAR(true, 'success', 'An email has been sent out to your account',2000); 
                      t.snackbarKey++;
                  } else {
                    t.snackbar = new SNACKBAR(true, 'error', 'Error sending the email',2000); 
                      t.snackbarKey++;
                  }
                });
              }

            // * redirect user to homepage
            setTimeout(function() {
              t.$router.push("/").catch(error => {
                console.log(error.message)
              });
            }, 1000); // ? added time out to allow the current user to be set
          })
          .catch((err) => {
            // ! firebase registering error
            t.registerErrorMessage = err;
            t.accountCreated = false;
            console.log("firebase registering error: " + err);
            t.snackbar = new SNACKBAR(true,'error',err,500); // update snack bar with error
            t.snackbarKey++;
          });
           }, 1000);
      } else {
        // ! user did not pass input validation
        t.accountCreated = false;
        t.snackbar = new SNACKBAR(true,'error',"Fill out all the fields correctly in order to proceed.",500); // update snack bar with error
        t.snackbarKey++;
      }
    },
    async readIntegration() {
      var t = this;
      await this.$firebase.db
        .collection("integrations")
        .where("name", "==", 'customEmail')
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            //docID = doc.id;
           // console.log("integration:  " + JSON.stringify(doc.data(), null,2));
            t.integration.push(doc.data());
          });
        });
    }
  },
  created() {
      this.readDocuments('lookupValues', 'lookupValues'); // get list of questions from database
         //var email = window.localStorage.getItem("emailForSignIn");
         //console.log("email: " +email)
  },
  mounted() {
    this.readIntegration();
  }
};
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}
</style>

