<template>
  <div>
    <v-divider class="ma-0 pa-0 mb-1 grey darken-2 divider"></v-divider>
    <v-row class="d-flex justify-spacearound  align-center no-gutters">
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        class="text-right d-flex justify-center justify-sm-start align-center"
      >
        <div class="px-3 my-2">
          <a href="http://www.vindico.net" target="_new"
            ><v-img
              alt="Vue logo"
              src="../assets/vlogo.png"
              max-width="120"
              contain
            ></v-img
          ></a>
        </div>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        class="px-2 mt-xs-4 text-center text-sm-end text-left grey--text text--darken-2"
      >
        &copy; Copyright {{ new Date().getFullYear() }}
        <a
          href="https://www.vindico.net/"
          target="_blank"
          style="color: var(--v-primary-base) !important; text-decoration: none;"
          ><b>Vindico</b></a
        >
      </v-col>
    </v-row>
    <v-dialog style="z-index: 3000 !important;"
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="secondary heading5 black--text">
          Privacy and Terms & Conditions
          <v-spacer></v-spacer>

          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-toolbar>

        <PrivacyTermsConditions />
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  class="secondary buttonmin white--text" @click="privacyDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrivacyTermsConditions from "./termsAndConditions.vue";

export default {
  name: "Footer",
  components: {
    PrivacyTermsConditions,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
  methods: {},
};
</script>
