<template>
  <v-row no-gutters class=" d-flex align-center justify-center">
    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="">
      <v-row class="d-flex align-center justify-center" no-gutters>
        <v-col cols="10" xs="10" sm="5" md="10" lg="10" xl="10" class="d-flex align-center ">
        <table cellpadding="0" cellspacing="0" class="">
            <tr>
              <td align="left">
                <v-img @click="goHome()" class="" alt="Vue logo" src="../assets/blues-logo.svg" max-height="80" max-width="70" contain></v-img>
              </td>
              <td>
                <div to="/" class="pl-1 heading6 text-left">
                  <div class="font-weight-bold white--text" style="line-height: 26px">Cardiff Blues</div>
                  <div class="secondary--text"  style="line-height: 26px">Pre-Event Screening</div>
                </div>
              </td>
            </tr>
          </table>
        </v-col>
        <v-spacer />
        <v-col cols="2" xs="2" sm="2" md="2" lg="2" xl="2" class="text-right">
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AppHeaderLoggedOut",
  methods: {
    goHome() {
      this.$router.push("/").catch((error) => {
        console.log(error.message);
      });
    },
  }
}
</script>
