import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    options: {
        customProperties: true
      },
    theme: {
        themes: {
          light: {
            primary: '#CB0F23',
            secondary: '#68AEE0', //#68AEE0',
            background: '#003263', //#343434
            darkgrey: '#1E1E1E',
            lightgrey: "AEAEAE",
            success: '#0A8137',
            warning: '#CB0F23',
            error: '#CB0F23'
          },
        },
    },
    icons: {
        iconfont: 'mdi' || 'icons8', // default - only for display purposes
    },
});

export default vuetify;
