<template>
  <v-layout column>
    <div class="text-center">
      <p class="subtitle-1 secondary--text">Please Wait, Signing you in</p>
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
     <!--notification-component-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
    </transition>
  </v-layout>
</template>

<script>
class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

export default {
  name: "LoginProgress",
  data() {
    return {
      snackbarKey: 0, // notifications
      snackbar: {}, //notifications
    }
  },
  mounted() {
    var t = this;
    // Confirm the link is a sign-in with email link.
    if (t.$firebase.auth.isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      t.$firebase.auth
        .signInWithEmailLink(email, window.location.href)
        .then(function() {
          // Clear email from storage.
          //t.$store.commit("setUser", data.user);
          window.localStorage.removeItem("emailForSignIn");
          t.snackbar = new SNACKBAR(true,'success','You have been logged in.',500); // update snack bar with success
          t.snackbarKey++;
          setTimeout(function(){
            t.$router.push('/').catch((err) => {
              console.log(err);
            });
          }, 1500);
        })
        .catch(function(error) {
          console.log(error);
          t.$store.commit("setUser", null);
          t.snackbar = new SNACKBAR(true,'error',"Your register link is invalid. Please enter enter your email again.",500); // update snack bar with error
          t.snackbarKey++;
          // Common errors could be invalid email and invalid or expired OTPs.
          setTimeout(function(){
            t.$router.push("/access").catch((err) => {
              console.log(err);
            });
          }, 1500);
        });
    }else{
      console.log("not working")
    }
  },
};
</script>