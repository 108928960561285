<template>
    <v-row class="justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" class="text-left pa-2">
            <v-row class="justify-left">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex align-center text-left ">
                <div class="heading4 secondary--text">Lookup Tables</div>
                <v-spacer />
                <v-btn class="secondary" @click="formAction('Add', '')">Add Lookup Table</v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" class="mt-1 text-left">
                   <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details ></v-text-field>
                   
                    </v-card-title>
                <v-data-table
                  :headers="computedHeaders"
                  :items="lookupTables"
                  :items-per-page="20"
                  class="elevation-1"
                  :search="search"
                >
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex justify-end">
                        <div style="width: 50px !important;" ><v-icon small @click="formAction('Edit', item)" class="icons8-edit mr-2"></v-icon>
                            <v-icon small @click="deleteLookupTable(item.id, item.lookupName)" class="icons8-trash"></v-icon>
                        </div>
                    </div>
                </template>
                <template v-slot:[`item.createdDatetime`]="{ item }">
                    {{ $moment(item.createdDatetime, 'x').format('DD MMM YY | HH:mm:ss') }}
                </template>
                <template v-slot:[`item.updatedDatetime`]="{ item }">
                    {{ $moment(item.updatedDatetime, 'x').format('DD MMM YY | HH:mm:ss') }}
                </template>
                <template v-slot:[`item.createdUserId`]="{ item }">
                    {{ lookupUser(item.createdUserId, 'fullName') }}
                </template>
                <template v-slot:[`item.updatedUserId`]="{ item }">
                    {{ lookupUser(item.updatedUserId, 'fullName') }}
                </template>                
                
                </v-data-table>
                   </v-card>
            </v-col>
            <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
            >

            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-left" v-if="showForm">
                <div class="darkgrey card  pa-4">
                <div class="heading6">{{ formType }} Lookup Table</div>
                <v-row align="center">
                    <v-col cols="12" class="text-left">
                        <v-form ref="lookupTables" v-model="valid" lazy-validation>
                        <v-text-field hide-details="auto" :rules="[(v) => !!v || 'Add a name for the lookup table.']" required class="mb-3" label="Name" v-model="lookupTableForm.lookupName" dark filled background="white" ></v-text-field>
                   
                        <v-text-field hide-details="auto" :rules="[(v) => !!v || 'Add a note for the lookup table.']" required label="Notes" v-model="lookupTableForm.lookupNotes" dark filled background="white" ></v-text-field>
                   </v-form>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center justify-end">
                        <div class="mr-2">
                            <v-btn depressed @click="showForm = false" class="grey">Close</v-btn>
                        </div>
                        <v-spacer />
                        <div class="mr-2">
                            <v-btn depressed v-if="formType === 'Edit'"  @click="deleteLookupTable(lookupTableForm.id, lookupTableForm.lookupName)" color="white"><span class="black--text">Delete Lookup Table</span></v-btn>
                        </div>
                        <div>
                            <v-btn depressed v-if="formType === 'Add'" @click="createLookupTable()" color="secondary">Save Lookup Table</v-btn>
                            <v-btn depressed v-if="formType === 'Edit'" @click="updateLookupTable()" color="secondary">Update</v-btn>
                        </div>
                    </v-col>
                </v-row>
                </div>
            </v-col>
            </transition>
        </v-row>
        <!-- <pre align="left">{{ lookupTableFormDefault }}</pre> -->
        <transition
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in"
        >
        <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
        </transition>
    </v-col>
       <v-dialog style="z-index: 3000 !important;"
      v-model="deleteDialog"
      persistent
      max-width="300">
      <v-card>
        <v-card-title  class="background white--text">
         Delete Lookup Table
        </v-card-title>
        <v-card-text class="mt-6"> <p class="subtitle1">Are you sure you want to delete the lookup table <strong>{{deleteName}}</strong>?</p></v-card-text>
        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn class="lightgrey" @click="noAction()"> No</v-btn>
          <v-btn class="secondary" @click="yesAction()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-row>
</template>

<script>

// Clases
class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

class LOOKUPTABLEFORM {
    constructor(id, lookupName, lookupNotes, createdUserId, createdDatetime, updatedUserId, updatedDatetime) {
        this.id = id;
        this.lookupName = lookupName;
        this.lookupNotes = lookupNotes;
        this.createdUserId = createdUserId;
        this.createdDatetime = createdDatetime;
        this.updatedUserId = updatedUserId;
        this.updatedDatetime = updatedDatetime;
    }
}

export default {
    data(){
        return {
            search: '',
            deleteDialog: false,
            deleteName: '',
            deleteId: '',
            snackbarKey: 0,
            snackbar: {},
            showForm: false,
            formType: '', 
            lookupTableForm: {},
            headers: [
                { text: 'ID', value: 'id', align: 'start', sortable: true, hidden: true },
                { text: 'Name', value: 'lookupName', align: 'start', sortable: true, hidden: false },
                { text: 'Notes', value: 'lookupNotes', align: 'start', sortable: true, hidden: false },
                { text: 'Created By', value: 'createdUserId', align: 'start', sortable: true, hidden: true },
                { text: 'Created Date', value: 'createdDatetime', align: 'start', sortable: true, hidden: true },
                { text: 'Updated By', value: 'updatedUserId', align: 'start', sortable: true, hidden: true },
                { text: 'Updated Date', value: 'updatedDatetime', align: 'start', sortable: true, hidden: true },
                { text: 'Action', value: 'actions', align: 'end', hidden: false },
            ],
            lookupTables: [],
            valid: true
        }
    },
    methods: {
        formAction(action, item) {
            this.formType = action;
            if (action === 'Add') {
                this.lookupTableForm = new LOOKUPTABLEFORM(null,'','', null, null, null, null); // clear lookupTable form
            } else {
                this.lookupTableForm = new LOOKUPTABLEFORM(item.id, item.lookupName, item.lookupNotes, item.createdUserId, item.createdDatetime, item.updatedUserId, item.updatedDatetime);
            }
            this.showForm = true; // show form
            this.$nextTick(() => {
                if (this.$vuetify.breakpoint.name === 'xs') {
                    this.$vuetify.goTo(700)
                }
            });
        },
        async createLookupTable() {
            if (this.$refs.lookupTables.validate()) {
                // this will update rather than create as to maintain the name of the table as the id
                this.lookupTableForm.id = this.lookupTableForm.lookupName.toLowerCase().replace(/\s/g, '');
                this.lookupTableForm.createdUserId = this.$store.getters.currUser.userID; // add userId to document
                this.lookupTableForm.createdDatetime = this.$moment().format('x'); // add created timestamp to document
                    
                var result = await this.updateDocument('lookupTables', this.lookupTableForm) // add menu through mixins
                if (result.code === 1) {
                    this.snackbar = new SNACKBAR(true,'success','Lookup Table Added',2000); // update snack bar with success
                    this.snackbarKey++
                    this.lookupTableForm = new LOOKUPTABLEFORM(null,'','', null, null, null, null); // clear lookupTable form
                    this.showForm = false;
                } else {
                    this.snackbar = new SNACKBAR(true,'error','Error Adding Lookup Table',3000); // update snack bar with error
                    this.snackbarKey++
                }
            } else{
                this.snackbar = new SNACKBAR(true,'error','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++;
            }
        },
        async updateLookupTable() {
            if (this.$refs.lookupTables.validate()) {
                var result = await this.updateDocument('lookupTables', this.lookupTableForm); // update lookupTable through mixins
                if (result.code === 1) { // if update lookupTable was successful
                    this.snackbar = new SNACKBAR(true,'success','Lookup Table Updated',2000); // update snack bar with success
                    this.snackbarKey++ // update snack bar component key to clear component
                    this.lookupTableForm = new LOOKUPTABLEFORM(null,'','', null, null, null, null); // clear lookupTable form
                    this.showForm = false; // hide form
                } else {
                    this.snackbar = new SNACKBAR(true,'error','Error Updating Lookup Table',3000); // update snack bar with error
                    this.snackbarKey++ // update snack bar component key to clear component
                }
            } else{
                this.snackbar = new SNACKBAR(true,'error','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++;
            }
        },
        deleteLookupTable(id, name) {
            this.deleteDialog = true;
            this.deleteName = name;
            this.deleteId = id;
        },    
        async yesAction() {
            var result = await this.deleteDocument('lookupTables', this.deleteId); // delete lookupTable through mixins
            if (result.code === 1) {
                 this.deleteDialog = false;
                this.showForm = false;
                this.snackbar = new SNACKBAR(true,'success','Lookup Table Deleted',1000); // update snack bar with success
                this.snackbarKey++ // update snack bar component key to clear component
            } else {
                 this.deleteDialog = false;
                this.showForm = false;
                this.snackbar = new SNACKBAR(true,'error','Error Deleting Lookup Table',3000); // update snack bar with error
                this.snackbarKey++ // update snack bar component key to clear component
            }
        },
        noAction(){
            this.deleteDialog = false;
            this.deleteName = '';
            this.deleteId = '';
        }
    },
    create() {
        // on create set default data objects
        this.lookupTableForm = new LOOKUPTABLEFORM(null,'','',null,null,null,null); // set default
        this.snackbar = new SNACKBAR(false,'black','',3000); // set default
    },
    async mounted() { 
        this.readDocuments('users', 'users'); // get list of questions from database
        this.readDocuments('lookupTables', 'lookupTables'); // get list of lookupTables from database
    }
}
</script>