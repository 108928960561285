<template>
  <v-row no-gutters class="d-flex justify-center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8" class="">
    <v-row class="pt-4 px-5 justify-center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> 
        <div class="heading6 white--text">Admin User Registration<br /></div>
        <p class="mt-1 text-left body2">
          Please use the form below to register as an admin user.  
        </p>
      </v-col>
    </v-row>
    
    <!--start of ACCESS-CODE-OVERLAY-->
    <v-overlay v-if="!authSuccess" :opacity="1"> 
      <v-dialog v-if="!authSuccess" v-model="dialogLogin" persistent style="z-index: 3000 !important;" width="500">
        <v-card class="login" color="background">
          <v-card-text>
              <!-- start of TITLE-ROW-->
              <v-row align="center" class="mt-4">
                <v-col cols="12">
                  <p class="headline white--text text-left font-weight-thin" >Cardiff Blues Pre-Event Screening<br /><strong class="secondary--text">Admin Registration</strong></p>
                </v-col>
                <v-col cols="12" class="mt-0">
                  <p class="subtitle1 white--text">
                    Please enter the access code you received in order to proceed
                    to the registration.
                  </p>
                </v-col>
              </v-row>
              <!-- end of TITLE-ROW-->
              <v-row>
                <v-col cols="12">
                  <v-row class="justify-center">
                    <!-- ACCESS-CODE-INPUT-->
                    <v-text-field
                      label="Enter Access Code"
                      v-model.trim="accessCode"
                      dark
                      filled
                      type="password"
                      v-on:keyup.enter="enterAccessCode()"
                    ></v-text-field>
                  </v-row>
                  <!-- start of ERROR-MESSAGE-->
                  <v-row class="justify-center ma-0 pa-0">
                    <transition name="custom-classes-transition" enter-active-class="animated flash">
                      <span
                        v-show="authCode === 0"
                        :class="{ 'error--text': !authSuccess }"
                        class="subtitle1 font-weight-bold"
                        >{{ authMessage }}</span>
                    </transition>
                  </v-row>
                  <!-- end of ERROR-MESSAGE-->
                </v-col>
                <v-col cols="12">
                  <v-row class="justify-center">
                    <!-- ENTER-ACCESS-CODE-BUTTON-->
                    <v-btn
                      class="secondary" large
                      @click="enterAccessCode()"
                      >Enter</v-btn>
                  </v-row>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
     <!--end of ACCESS-CODE-OVERLAY-->

     <!-- start of USER-INPUT-->
    <v-row class="px-5 justify-center"> 
      <v-col cols="12" xs="12" sm="6">
        <v-window v-model="step">
          <!--GENERAL-DETAILS-WINDOW-->
          <v-window-item :value="1">
            <v-form ref="registerUser" v-model="valid" lazy-validation>
              <v-text-field
                solo
                class="mb-1"
                v-model.trim="user.email"
                label="Email"
                hide-details="auto"
                :rules="emailRules"
                required
              ></v-text-field>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.fullName"
                label="Full Name"
                hide-details="auto"
                :rules="nameRules"
                required
              ></v-text-field>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.contactNumber"
                label="Contact Number"
                hide-details="auto"
                :rules="telephoneNumberRules"
                required
              ></v-text-field>
              
              <!-- <v-text-field
                solo
                class="mb-1"
                v-model="user.company"
                label="Company/Organisation"
                hide-details="auto"
                :rules="nameRules"
                required
              ></v-text-field>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.jobTitle"
                label="Job title"
                hide-details="auto"
                :rules="nameRules"
                required
              ></v-text-field> -->
              <v-checkbox
                color="white"
                v-model="user.privacyPolicyConsent"
                hide-details="auto"
                :rules="[(v) => !!v || 'Required']"
                required
                dark
                
              >
                <template v-slot:label>
                  <div>
                    I have completed this form as accurately as possible and accept the
                  <span
                    style="cursor:pointer !important; color: var(--v-secondary-base) !important;"
                    v-on:click="privacyDialog = true"
                    >Privacy Policy and Terms & Conditions</span>
                  </div>
                </template>
              </v-checkbox>
            </v-form>
            <!--NEXT-BUTTON-->
            <div class="text-right">
              <v-btn @click="next" large class="buttonmin mt-3 secondary">Next</v-btn>
            </div>
          </v-window-item>

          <!--PASSWORD-WINDOW-->
          <v-window-item :value="2">
            <v-form ref="userPassword" v-model="valid1" lazy-validation>
              <v-text-field
                v-model.trim="password"
                solo
                class="mb-1"
                hide-details="auto"
                label="Password"
                :append-icon="show ? 'icons8-eye' : 'icons8-invisible'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :rules="passwordRules"
                required
              ></v-text-field>

              <v-text-field
                v-model.trim="confirmPassword"
                solo
                class="mb-2"
                hide-details="auto"
                label="Confirm Password"
                :append-icon="show1 ? 'icons8-eye' : 'icons8-invisible'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                :rules="[confirmRules, passwordConfirmationRule]"
                required
              ></v-text-field> 
            </v-form>
            <v-row class="px-4 mb-4">
              <v-btn @click="step--" class=" mt-3 secondary">Back</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="register" :loading="loading" :disabled="loading" class=" mt-3 secondary">Register</v-btn>
            </v-row>
          </v-window-item>

          <!-- Error message -->
          <v-window-item :value="3">
            <div class="pa-4 text-center" v-if="accountCreated === true">
                  <v-progress-linear indeterminate color="primary" ></v-progress-linear>
              <h3 class="title font-weight-light mb-2">
                Welcome to the admin dashboard of Cardiff Blues Events
              </h3>
              <span class="caption grey--text">Thanks for signing up! You will be redirected to the home page</span>
            </div>
            <div class="pa-4 text-center" v-else>
              <h3 class="title font-weight-light mb-2">Something went wrong</h3>
              <h4 v-if="registerErrorMessage != null">{{registerErrorMessage}}</h4>
              <br/><br/>
              <span class="caption grey--text">Try again!</span>
            </div>
          </v-window-item>
        </v-window>


      </v-col>
    </v-row>
            <v-row class="px-5 justify-center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="text-center"> 
        <appfooter />
      </v-col>
    </v-row>
    <!-- end of USER-INPUT-->

     <!--notification-component-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
    </transition>
      <v-dialog style="z-index: 3000 !important;"
      v-model="privacyDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="background">
        <v-toolbar dark color="secondary heading5 black--text">
          Privacy and Terms & Conditions
          <v-spacer></v-spacer>

          <v-icon class="black--text" @click="privacyDialog = false">mdi-close</v-icon>
        </v-toolbar>

        <PrivacyTermsConditions />
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import PrivacyTermsConditions from "../../components/termsAndConditions.vue";
import axios from "axios";

class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}
export default {
  name: "RegisterAdmin",
    components: {
    PrivacyTermsConditions,
  },
  data() {
    return {
      snackbarKey: 0, // notifications
      snackbar: {}, //notifications
      step: 1, //window step
      valid: true, // validation for general information form
      valid1: true, // validation for password form
      show: false, // show/hide password
      show1: false, // show/hide confirm password
      user: {
        userID: "",
        userLevel: 100,
        userType: "admin",
        fullName: "",
        contactNumber: "",
        email: "",
        company: "",
        jobTitle: "",
        privacyPolicyConsent: false,
        accountCreatedAt: "",
        visitorType: "Cardiff Blues Group Staff"
      },
      accountCreated: true, // show thank you/ try gain message at the end
      loader: null,
      loading: false,
      password: "", // password input
      confirmPassword: "", // check password
      privacyDialog: false,
      // * ACCESS CODE VARIABLES
      // TODO - change access password
      accessPassword: "CF101JASWAPP!!", // ? ACCESS PASSwORD
      accessCode: '', // TODO remove before go live // user input code
      dialogLogin: true, // login dialog
      authSuccess: false, // TODO change back to false before go live // * when true - closes the overlay
      authMessage: null, // message displayed to user
      authCode: null, // * 1 for correct password, 0 for incorrect

      // * VALIDATIONS RULES
      nameRules: [(v) => !!v || "Full name is required"],
      confirmRules: [(v) => !!v || "Confirm password required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      telephoneNumberRules: [(v) => !!v || "Telephone Number is required"],
      registerErrorMessage: null,
      integration: []
    };
  },
   watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  computed: {
    // * Checks if the password matches the confirm password
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || "Password must match";
    },
  },
  methods: {
    enterAccessCode() {
      // * Access code validation
      var v = this;
      if (this.accessCode === this.accessPassword) {
        v.authSuccess = true; // * close the overlay
        v.authCode = 1; // * success code
        v.authMessage = "The access code was correct";
        v.accessCode = null;
      } else {
        v.authSuccess = false; // ! keep the overlay open
        v.authCode = 0; // ! error code
        v.authMessage = "The access code you have entered is incorrect";
      }
    },
    next() {
      if (this.$refs.registerUser.validate()) {
        this.step++; // * move to the next step 
      } else {
        // // console.log("not valid");
        // ! did not pass validation
        this.snackbar = new SNACKBAR(true,'error',"Fill out all the fields correctly in order to proceed.",500); // update snack bar with error
        this.snackbarKey++;
      }
    },
    register() {
      var t = this;
      if (this.$refs.userPassword.validate()) {
        this.step++; // * move to the next step 
        // * create user
        t.user.email = t.user.email.toLowerCase();
        t.$firebase.auth.createUserWithEmailAndPassword(t.user.email, t.password).then((user) => {
            // //console.log(JSON.stringify(user, null, 2));
            t.user.userID = user.user.uid; // * assign firebase auth user id to firebase user in collection
            t.user.accountCreatedAt = moment().toISOString();
            ////console.log("uid: " + user.uid + "\nsecond type: " + user.user.uid);
            /////console.log("User: " + JSON.stringify(t.user, null, 2));
            // * add to the users collection
            t.$firebase.db
              .collection("users")
              .doc(user.user.uid)
              .set(t.user)
              .then((Result) => {
                console.log("result: " + Result);
              })
              .catch((err) => {
                console.log(err);
              });
            t.accountCreated = true;
             // * assign this user to the current logged in user
            //t.$store.commit("setUser", user);
            t.snackbar = new SNACKBAR(true,'success',"Your account was created.",500); // update snack bar with success
            t.snackbarKey++;
            

            // * send verification email to user
            var currUser = t.$firebase.auth.currentUser;
            currUser.sendEmailVerification().then(function() {
                t.snackbar = new SNACKBAR(true,'yellow',"An account verification email has been sent to you.",500); // update snack bar with error
                t.snackbarKey++;
              })
              .catch(function(error) {
                // ! verification email error
                console.log("send verification email error: " + error);
                t.snackbar = new SNACKBAR(true,'error',error,500); // update snack bar with error
                t.snackbarKey++;
              });

             // ? send custom email
              if(t.integration != undefined && t.integration[0].enabled === true){
                axios.get(t.integration[0].functionURL +"?fullName=" + t.user.fullName + "&email=" + t.user.email).then(response => {

                  if (response.data.result === true) {
                      t.snackbar = new SNACKBAR(true, 'success', 'An email has been sent out to your account',2000); 
                      t.snackbarKey++;
                  } else {
                    t.snackbar = new SNACKBAR(true, 'error', 'Error sending the email',2000); 
                      t.snackbarKey++;
                  }
                });
              }

            // * redirect user to homepage
            setTimeout(function() {
              t.$router.push("/").catch(error => {
                console.log(error.message)
              });
            }, 1500); // ? added time out to allow the current user to be set
          })
          .catch((err) => {
            // ! firebase registering error
            t.registerErrorMessage = err;
            t.accountCreated = false;
            console.log("firebase registering error: " + err);
            t.snackbar = new SNACKBAR(true,'error',err,500); // update snack bar with error
            t.snackbarKey++;
          });
      } else {
        // ! user did not pass input validation
        t.accountCreated = false;
        t.snackbar = new SNACKBAR(true,'error',"Fill out all the fields correctly in order to proceed.",500); // update snack bar with error
        t.snackbarKey++;
      }
    },
  
   async readIntegration() {
      var t = this;
      await this.$firebase.db
        .collection("integrations")
        .where("name", "==", 'customEmail')
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            //docID = doc.id;
           // console.log("integration:  " + JSON.stringify(doc.data(), null,2));
            t.integration.push(doc.data());
          });
        });
    }
  },
  mounted() {
    this.readIntegration();
  }
};
</script>
