<template>
  <v-card-text class="background mt-4 white--text">
    <p class="subtitle1">
      <strong class="white--text  "
        >Click on the following links to read the terms and conditions.
      </strong>
    </p>
    <v-row class="background">
      <v-col cols="12" xs="12" sm="6" md="3">
        <v-btn large block class="secondary" href="https://cardiffblues.com/privacy-and-cookies" target="_blank">Privacy&Cookies</v-btn>
      </v-col>
    </v-row>

    <!-- <h1> Code of Conduct </h1>
    <br>
    <p  class="subtitle1"> <strong class="white--text"> We are looking forward to safely welcoming fans back to Cardiff Arms Park for the Under-20 Six Nations. </strong></p>
    <p  class="subtitle1"> <strong class="white--text"> The health, safety and wellbeing of everyone attending Under-20 Six Nations fixtures will be our top priority, so please take the time to read the following information and complete a mandatory health screening questionnaire before travelling to Cardiff Arms Park.</strong> </p>
    <p  class="subtitle1"> <strong class="white--text"> We ask those fans planning on attend Under-20 Six Nations fixtures work with us and comply with the guidelines in order to stop the spread of COVID-19, help to continue to hold matches with supporters present and ensure a safe and enjoyable return for all.</strong> </p>
    <h1> COVID-19 – Cardiff Arms Park Code of Conduct </h1>
    <br>
    <p  class="subtitle1"> <strong class="white--text"> To help us create a safe environment for everyone attending or working at Under-20 Six Nations events, by attending you are agreeing to comply to the following code of conduct:</strong> </p>
    <p class="subtitle1"> <strong class="white--text"> 1. Do not attend any fixture if you, or any of your household, have shown any COVID-19 symptoms in the last 14 days. This includes, but is not limited to, a temperature of 37.8 degrees or above, a new continuous cough, a loss of sense of taste or smell. If you have symptoms, please follow Government guidance <a href="https://www.nhs.uk/conditions/coronavirus-covid-19/symptoms/">www.nhs.uk/conditions/coronavirus-covid-19/symptoms</a> </strong> </p>
    <p class="subtitle1"> <strong class="white-text"> 2. Cardiff Arms Park require the details of those attending. You must be the named supporter on the ticket and any supporters aged 16 or over may be asked to provide photographic identification before entering the ground. If your photo ID check is not validated, you will not be permitted entry. Applicants will be sent their ticket via email to be downloaded onto a mobile device or printed at home. For this fixture, there will be no opportunity to forward the ticket, and no paper tickets will be printed.</strong></p>
    <p class="subtitle1"> <strong class="white-text">3. If you are allocated a ticket for a Under-20 Six Nations match at Cardiff Arms Park, you will be required to complete a health questionnaire, as well as download the NHS Covid App on your phone and scan into the Cardiff Arms Park QR code before you enter the site. All attendees are required to provide this information and tickets will only become active upon provision of the relevant information. </strong></p>
    <p class="subtitle1"> <strong class="white-text">4. The health questionnaire must be completed. Failure to complete the health questionnaire will result in your ticket being blocked.</strong></p>
    <p class="subtitle1"> <strong class="white-text">5. You must observe social distancing at all times, including on the way to and from the stadium, as well as during the match. If travelling by public transport, please make sure you are complying with government and public transport operator guidelines and regulations.</strong></p>
    <p class="subtitle1"> <strong class="white-text">6. Any supporter aged 11 and over must wear a face covering at all times.</strong></p>
    <p class="subtitle1"> <strong class="white-text">7. The only time you do not need to wear a face covering is when eating or drinking. </strong></p>
    <p class="subtitle1"> <strong class="white-text">8. If you are medically exempt from wearing a face covering under Government guidelines, you do not have to wear one, but you may wish to use one of the Government resources to demonstrate this, including the use of a GP letter and or hidden disabilities card and lanyard. Any exemptions must be made known to Cardiff Arms Park in advance of attending. Cardiff Arms Park reserve the right to refuse entry if a supporter will not wear a face mask and has not declared themselves medically exempt in advance. </strong></p>
    <p class="subtitle1"> <strong class="white-text">9. You are responsible for your own health. Act in ways that protect your health and the health of others and avoid acting in a way that might risk the health of fellow supporters, Cardiff Arms Park staff, and any others affected by these acts or omissions. Any deliberate acts which risk further spread of COVID may be reported to police.</strong></p>
    <p class="subtitle1"> <strong class="white-text">10. To help reduce queuing time and help us to maintain social distancing, we would encourage supporters to avoid bringing bags where possible. Only bags of A4 size or smaller will be permitted and will be subject to search. </strong></p>
    <p class="subtitle1"> <strong class="white-text">11. Whilst inside the ground, always observe social distancing and avoid close contact with those not in your social or support bubble. As a minimum, always ensure you are one metre away from other people and take necessary mitigating measures. </strong></p>
    <p class="subtitle1"> <strong class="white-text">12. You must only sit in the seat or stand in the location allocated to you on your ticket – do not move to any other location even if there are other members of your household present. </strong></p>
    <p class="subtitle1"> <strong class="white-text">13. If you do need to leave your seat or area, wait until the area is as clear as possible before doing so. If passing other supporters in the seating rows, please avoid face-to- face contact. </strong></p>
    <p class="subtitle1"> <strong class="white-text">14. We encourage all fans to stay in your seats or location as much as possible throughout the duration of the game. </strong></p>
    <p class="subtitle1"> <strong class="white-text">15. Wash your hands frequently using the hand sanitiser or soap provided. Please avoid touching your face, handles or railings where possible. </strong></p>
    <p class="subtitle1"> <strong class="white-text">16. Please avoid hugs, high-fives, handshakes and any close contact with people not in your bubble. </strong></p>
    <p class="subtitle1"> <strong class="white-text">17. In the event that you develop COVID-19 symptoms whilst at the stadium, please notify the nearest member of staff immediately so they can follow the correct health and safety protocols. </strong></p>
    <p class="subtitle1"> <strong class="white-text">18. If at any point during the match you are unable to avoid touching the match ball if it is kicked out of play and into the stands, please refrain from throwing it directly back on to the pitch. A member of staff will collect the ball in order to sanitise it </strong></p>
    <p class="subtitle1"> <strong class="white-text">19. Please follow instructions from stewards and club staff at all times. Particularly during pre- match, half time and after the final whistle.
   </strong></p>
    <p class="subtitle1"> <strong class="white-text">20. By not complying to this Code of Conduct, you are jeopardising public health and the continuation o supporters attending sporting events. Any breaches of the code will be dealt with in the strongest possible terms. </strong></p>
    <p class="subtitle1"> <strong class="white-text">Thank you for your continued support and cooperation. </strong></p>
    <h1> Summary: </h1>
    <br>
    <p class="subtitle1"> <strong class="white-text"> - You must complete the mandatory health questionnaire and read and accept the Code of Conduct </strong> </p>
    <p class="subtitle1"> <strong class="white-text"> - Face coverings at the fixture are mandatory</strong> </p>
    <p class="subtitle1"> <strong class="white-text"> - You must be in a bubble within your household</strong> </p>
    <p class="subtitle1"> <strong class="white-text"> - You must be able to print your ticket at home</strong> </p> -->


  </v-card-text>
</template>

<script>
export default {
  name: "PrivacyTermsConditions",
};
</script>
