<template>
    <v-row class="justify-center" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" class="text-left pa-2">
            <v-row class="justify-left">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex align-center text-left ">
                <div class="heading4 secondary--text">Lookup Value</div>
                <v-spacer />
                <v-btn class="secondary" @click="formAction('Add', '')">Add Lookup Value</v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" class="mt-1 text-left">
                   <v-card>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details ></v-text-field>
                   
                    </v-card-title>
                <v-data-table
                  :headers="computedHeaders"
                  :items="lookupValues"
                  :items-per-page="20"
                  class="elevation-1"
                  :search="search"
                >
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="d-flex justify-end">
                        <div style="width: 50px !important;" ><v-icon small @click="formAction('Edit', item)" class="icons8-edit mr-2"></v-icon>
                            <v-icon small @click="deleteLookupValue(item.id, item.text)" class="icons8-trash"></v-icon>
                        </div>
                    </div>
                </template>



                <template v-slot:[`item.createdDatetime`]="{ item }">
                    {{ $moment(item.createdDatetime, 'x').format('DD MMM YY | HH:mm:ss') }}
                </template>
                <template v-slot:[`item.updatedDatetime`]="{ item }">
                    {{ $moment(item.updatedDatetime, 'x').format('DD MMM YY | HH:mm:ss') }}
                </template>
                <template v-slot:[`item.createdUserId`]="{ item }">
                    {{ lookupUser(item.createdUserId, 'fullName') }}
                </template>
                <template v-slot:[`item.updatedUserId`]="{ item }">
                    {{ lookupUser(item.updatedUserId, 'fullName') }}
                </template>                
                
                </v-data-table>
                   </v-card>
            </v-col>
            <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
            >

            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-left" v-if="showForm">
                <div class="darkgrey card  pa-4">
                <div class="heading6">{{ formType }} Lookup Value</div>
                <v-row align="center">
                    <v-col cols="12" class="text-left">
                        <v-form ref="lookupValues" v-model="valid" lazy-validation>
                        <v-select class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Select a Lookup Table.']" required label="Lookup Table" v-model="lookupValueForm.lookupTable" dark filled background="white" :items="lookupTables" item-text="lookupName" item-value="id" />
                        <v-text-field @input="lookupValueForm.value = lookupValueForm.text.toLowerCase().replace(/\s/g, '');" hide-details="auto" :rules="[(v) => !!v || 'Add a name for the lookup value.']" required class="mb-3" label="Name" v-model="lookupValueForm.text" dark filled background="white" ></v-text-field>
                   
                        <v-text-field hide-details="auto" :rules="[(v) => !!v || 'Add a lookup value.']" required label="Value" v-model="lookupValueForm.value" dark filled background="white" ></v-text-field>
                   </v-form>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center justify-end">
                        <div class="mr-2">
                            <v-btn depressed @click="showForm = false" class="grey">Close</v-btn>
                        </div>
                        <v-spacer />
                        <div class="mr-2">
                            <v-btn depressed v-if="formType === 'Edit'"  @click="deleteLookupValue(lookupValueForm.id, lookupValueForm.text)" color="white"><span class="black--text">Delete Lookup Value</span></v-btn>
                        </div>
                        <div>
                            <v-btn depressed v-if="formType === 'Add'" @click="createLookupValue()" color="secondary">Save Lookup Value</v-btn>
                            <v-btn depressed v-if="formType === 'Edit'" @click="updateLookupValue()" color="secondary">Update Lookup Value</v-btn>
                        </div>
                    </v-col>
                </v-row>
                </div>
            </v-col>
            </transition>
        </v-row>
        <!-- <pre align="left">{{ lookupValueFormDefault }}</pre> -->
        <transition
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
            mode="out-in"
        >
        <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
        </transition>
    </v-col>
     <v-dialog style="z-index: 3000 !important;"
      v-model="deleteDialog"
      persistent
      max-width="300">
      <v-card>
        <v-card-title  class="background white--text">
         Delete Lookup Value
        </v-card-title>
        <v-card-text class="mt-6"> <p class="subtitle1">Are you sure you want to delete the lookup value <strong>{{deleteName}}</strong>?</p></v-card-text>
        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn class="lightgrey" @click="noAction()"> No</v-btn>
          <v-btn class="secondary" @click="yesAction()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-row>

</template>

<script>

// Clases
class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

class LOOKUPVALUEFORM {
    constructor(id, text, value, lookupTable, createdUserId, createdDatetime, updatedUserId, updatedDatetime) {
        this.id = id;
        this.text = text;
        this.value = value;
        this.lookupTable = lookupTable;
        this.createdUserId = createdUserId;
        this.createdDatetime = createdDatetime;
        this.updatedUserId = updatedUserId;
        this.updatedDatetime = updatedDatetime;
    }
}

export default {
    data(){
        return {
            search: '',
            deleteDialog: false,
            deleteName: '',
            deleteId: '',
            snackbarKey: 0,
            snackbar: {},
            showForm: false,
            formType: '', 
            lookupValueForm: {},
            headers: [
                { text: 'ID', value: 'id', align: 'start', sortable: true, hidden: true },
                { text: 'Name', value: 'text', align: 'start', sortable: true, hidden: false },
                { text: 'Value', value: 'value', align: 'start', sortable: true, hidden: false },
                { text: 'Lookup Table', value: 'lookupTable', align: 'start', sortable: true, hidden: false },               
                { text: 'Created By', value: 'createdUserId', align: 'start', sortable: true, hidden: true },
                { text: 'Created Date', value: 'createdDatetime', align: 'start', sortable: true, hidden: true },
                { text: 'Updated By', value: 'updatedUserId', align: 'start', sortable: true, hidden: true },
                { text: 'Updated Date', value: 'updatedDatetime', align: 'start', sortable: true, hidden: true },
                { text: 'Action', value: 'actions', align: 'end', hidden: false },
            ],
            lookupTables: [],
            lookupValues: [],
            valid: true
        }
    },
    methods: {
        formAction(action, item) {
            this.formType = action;
            if (action === 'Add') {
                this.lookupValueForm = new LOOKUPVALUEFORM(null,'','','', null, null, null, null); // clear lookupValue form
            } else {
                this.lookupValueForm = new LOOKUPVALUEFORM(item.id, item.text, item.value, item.lookupTable, item.createdUserId, item.createdDatetime, item.updatedUserId, item.updatedDatetime);
            }
            this.showForm = true; // show form
            this.$nextTick(() => {
                if (this.$vuetify.breakpoint.name === 'xs') {
                    this.$vuetify.goTo(700)
                }
            });
        },
        async createLookupValue() {
            if (this.$refs.lookupValues.validate()) {
                delete this.lookupValueForm.id; // remove the id as we are creating a new lookupValue
                var result = await this.createDocument('lookupValues', this.lookupValueForm) // add menu through mixins
                if (result.code === 1) {
                    this.snackbar = new SNACKBAR(true,'success','Lookup Value Added',2000); // update snack bar with success
                    this.snackbarKey++
                    this.lookupValueForm = new LOOKUPVALUEFORM(null,'','','', null, null, null, null); // clear lookupValue form
                    this.showForm = false;
                } else {
                    this.snackbar = new SNACKBAR(true,'error','Error Adding Lookup Value',3000); // update snack bar with error
                    this.snackbarKey++
                }
            } else{
                this.snackbar = new SNACKBAR(true,'error','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++;
            }
        },
        async updateLookupValue() {
            if (this.$refs.lookupValues.validate()) {
                var result = await this.updateDocument('lookupValues', this.lookupValueForm); // update lookupValue through mixins
                if (result.code === 1) { // if update lookupValue was successful
                    this.snackbar = new SNACKBAR(true,'success','Lookup Value Updated',2000); // update snack bar with success
                    this.snackbarKey++ // update snack bar component key to clear component
                    this.lookupValueForm = new LOOKUPVALUEFORM(null,'','','', null, null, null, null); // clear lookupValue form
                    this.showForm = false; // hide form
                } else {
                    this.snackbar = new SNACKBAR(true,'error','Error Updating Lookup Value',3000); // update snack bar with error
                    this.snackbarKey++ // update snack bar component key to clear component
                }
            } else{
                this.snackbar = new SNACKBAR(true,'error','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++;
            }
        },   
        deleteLookupValue(id, name) {
            this.deleteDialog = true;
            this.deleteName = name;
            this.deleteId = id;
        },     
        async yesAction() {
            var result = await this.deleteDocument('lookupValues', this.deleteId); // delete lookupValue through mixins
            if (result.code === 1) {
                  this.deleteDialog = false;
                this.showForm = false;
                this.snackbar = new SNACKBAR(true,'success','Lookup Value Deleted',1000); // update snack bar with success
                this.snackbarKey++ // update snack bar component key to clear component
            } else {
                  this.deleteDialog = false;
                this.showForm = false;
                this.snackbar = new SNACKBAR(true,'error','Error Deleting Lookup Value',3000); // update snack bar with error
                this.snackbarKey++ // update snack bar component key to clear component
            }
        },
        noAction(){
            this.deleteDialog = false;
            this.deleteName = '';
            this.deleteId = '';
        }
    },
    create() {
        // on create set default data objects
        this.lookupValueForm = new LOOKUPVALUEFORM(null,'','','',null,null,null,null); // set default
        this.snackbar = new SNACKBAR(false,'black','',3000); // set default
    },
    async mounted() { 
        this.readDocuments('lookupTables', 'lookupTables'); // get list of questions from database
        this.readDocuments('users', 'users'); // get list of questions from database
        this.readDocuments('lookupValues', 'lookupValues'); // get list of lookupValues from database
    }
}
</script>